import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import ShadowImage from "../components/shadowImage.js"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import TitleHeader from "../components/titleHeader"
import Meta from "../components/meta"
import BodyText from "../components/bodyText"
import Headline from "../components/headline"
import Footer from "../components/footer"
import "../styles/pages/_careers.scss"

export default class CareersPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const careersData = this.props.data.allContentfulCareersPage.nodes[0]
    const generalData = this.props.data.allContentfulGeneral.nodes[0]

    return (
      <div className="careers">
        <Meta
          title={careersData.seo.title}
          description={careersData.seo.description.description}
          image={careersData.seo.socialImage.file.url}
          url={this.props.location.href}
          imgAlt={careersData.seo.socialImage.description}
        />
        <Header />
        <TitleHeader title={careersData.pageTitle} />
        <div className="content">
          <div className="text">
            <Headline text={careersData.subtitle.json} />
            <BodyText text={careersData.bodyText.json} />
            <div className="ctas">
              {careersData.ctAs.map((cta, n) => {
                return (
                  <Link to={cta.link} key={n}>
                    {cta.buttonText}
                    <span className="cta-arrow">
                      <img
                        src={cta.buttonIcon.file.url}
                        alt={cta.buttonIcon.description}
                      />
                    </span>
                  </Link>
                )
              })}
            </div>
          </div>
          <div class="overlap-content">
            <ShadowImage
              image={careersData.mainImage}
              shadow={generalData.redShadow}
            />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query careersPageQuery {
    allContentfulCareersPage {
      nodes {
        pageTitle
        subtitle {
          json
        }
        bodyText {
          json
        }
        ctAs {
          buttonIcon {
            description
            file {
              url
            }
          }
          buttonText
          link
        }
        mainImage {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
        }
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
    allContentfulGeneral {
      nodes {
        redShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
